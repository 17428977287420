<template>
  <div>
    <Navbar text="Investissez dans une formation ✨"/>

    <div class="is-max-desktop content is-vcentered " style="padding-bottom: 20px; margin-bottom: 0 ; margin-left: 10%; margin-right: 10%; margin-top:30px" >

      <br/>
      <p style="font-family: 'Montserrat Alternates', sans-serif; color: black; font-weight: bold; font-size: x-large">Choisissez le domaine</p>
      <br/>

      <div class="columns">
        <div class="column">

          <div class="card-buttom card card-content has-text-centered">
            <a href="https://www.matchers.fr/nos-formations-marketing" target="_blank">
              <img src="https://www.pngmart.com/files/7/SEO-PNG-Clipart.png"  style="width=100px;height :100px" />
              <p class="content is-medium" style="font-weight: bold">SEO </p>
            </a>
          </div>

          <div class="card-buttom card card-content has-text-centered" style="margin-top:15px">
            <a href="https://www.matchers.fr/finance" target="_blank">
              <img src="https://drtpartners.com/wp-content/uploads/2020/11/feature2-free-img.png" style="width=100px;height :100px"/>
              <p class="content is-medium" style="font-weight: bold">Finance </p>
            </a>
          </div>

        </div>
        <div class="column">

          <div class="card-buttom card card-content has-text-centered" >
            <a href="https://www.matchers.fr/tech-rgpd" target="_blank">
              <img src="https://www.webcom-agency.fr/wp-content/uploads/2019/05/creation-site-web-toulouse-webcom-agency.png" style="width=100px;height :100px" />
              <p class="content is-medium" style="font-weight: bold">Site internet </p>
            </a>
          </div>

          <div class="card-buttom card card-content has-text-centered" style="margin-top:15px">
            <a href="https://www.matchers.fr/nos-formations-growth-et-acquisition" target="_blank">
              <img src="https://cdn.pixabay.com/photo/2015/11/03/09/19/statistics-1020319_960_720.png" style="width=100px;height :100px"/>
              <p class="content is-medium" style="font-weight: bold">Growth </p>
            </a>
          </div>

        </div>
        <div class="column">

          <div class="card-buttom card card-content has-text-centered">
            <a href="https://www.matchers.fr/crm-marketing-automation" target="_blank">
              <img src="https://www.pngmart.com/files/7/Digital-Marketing-PNG-Transparent-Image.png" style="width=100px;height :100px"/>
              <p class="content is-medium" style="font-weight: bold">Marketing </p>
            </a>
          </div>

          <div class="card-buttom card card-content has-text-centered" style="margin-top:15px">
            <a href="https://www.matchers.fr/crm-marketing-automation" target="_blank">
              <img src="https://pics.freeicons.io/uploads/icons/png/20830632741582956833-512.png" style="width=100px;height :100px"/>
              <p class="content is-medium" style="font-weight: bold">Communication </p>
            </a>
          </div>

        </div>
        <div class="column is-two-fifths">

          <div class="card card-content has-text-centered">
              <p class="content is-medium" style="font-weight: bold">Vous souhaitez plus d’information et un accompagnement pour effectuer la formation qui vous convient ? </p>
              <p class="content is-small" >On vous accompagne pour mettre en place la formation qui vous convient sans débourser un centime !
Des formations sur-mesure avec une pédagogie basée sur vos objectifs,, la mise en relation avec LE formateur parfait, un suivi administratif et budgétaire... et tout ça gratuitement !? </p>
              <p class="content is-small" >Vous avez un doute ? Dans ce cas </p>
              <div >
                <a class="button" href="https://meetings.hubspot.com/ssilvi" target="_blank">Prenez rendez-vous ! </a>
              </div>
          </div>

        </div>
      </div>
      
    </div>

    <Footer/>


  </div>


</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "FunnelOne",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      companyName : "",
      idcc: "",
      result : '',
      isLoading: false,
      isFullPage: false,
      knowIdcc:true,
      idcc_value:null,
    };
  },

  methods:{
  }
}
</script>
<style>
.card-buttom {
  cursor : pointer;
}
.button {
  font-family: "Montserrat Alternates", sans-serif;
    font-weight: bold;
    font-size: medium;
    width: 200px;
    height: 40px;
    background: #FF8A55;
    color: rgb(255, 255, 255) !important;
}
a{
  color:#47423f
}
</style>
